import { Route, Routes } from 'react-router-dom'
import { Suspense } from 'react'
import { ThemeProvider } from 'styled-components'
import globalTheme from 'styles/theme'
import Dashboard from './pages/Dashboard/dashboard'
import Outstanding from './pages/outStanding/outStanding'

import LoginComponent from './pages/Login/login'
import SingUp from './pages/signUp/signUP'

function AppRoutes() {
  return (
    <>
      <Suspense fallback={<div className="container"></div>}>
        <ThemeProvider theme={globalTheme}>
          <Routes>
            <Route path="/" element={<LoginComponent />} />
            <Route path="/sing-up" element={<SingUp />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/outstanding" element={<Outstanding />} />
          </Routes>
        </ThemeProvider>
      </Suspense>
    </>
  )
}

export default AppRoutes
