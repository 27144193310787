import CustomButton from 'components/button/CommonButton'
import { FlexBoxInput, SingUpMain, SingUpSection, Tittle } from './signUp.styles'
import Input from '../../components/input/input'
import { FormProvider, useForm } from 'react-hook-form'
import DealerInfo from './type'
import { string, object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const SingUp = () => {
  const schema = object({
    dealerMaster: string().required('Dealer Master is required'),
    dealerID: string().required('Dealer ID is required'),
    dealerParent: string().required('Dealer Parent is required'),
    locationCode: string().required('Location Code is required'),
    saleCode: string().required('Sale Code is required'),
    outletCode: string().required('Outlet Code is required'),
    dealerMapCode: string().required('Dealer Map Code is required'),
    billSeriesNo: string().required('Bill Series No is required'),
    locationDescription: string().required('Location Description is required'),
    dealerName: string().required('Dealer Name is required'),
    dealerCity: string().required('Dealer City is required'),
    dealerEmailID: string().email('Invalid email format').required('Dealer Email ID is required'),
    dealerAdminName: string().required('Dealer Admin Name is required'),
    dealerAdminContactNo: string()
      .matches(/^[0-9]+$/, 'Dealer Admin Contact number')
      .required('Dealer Admin Contact No. is required'),
  })

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      billSeriesNo: '',
      dealerAdminContactNo: '',
      dealerAdminName: '',
      dealerCity: '',
      dealerEmailID: '',
      dealerID: '',
      dealerMapCode: '',
      dealerMaster: '',
      dealerName: '',
      dealerParent: '',
      locationCode: '',
      locationDescription: '',
      outletCode: '',
      saleCode: '',
    },
  })

  const onSubmit = (data: DealerInfo) => {
    console.log(data)
  }
  return (
    <SingUpMain>
      <SingUpSection>
        <Tittle>Sign Up</Tittle>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FlexBoxInput>
              <Input type="text" label="Dealer Master" name="dealerMaster" />
              <Input type="text" label="Dealer ID" name="dealerID" />
            </FlexBoxInput>
            <FlexBoxInput>
              <Input type="text" label="Dealer Parent" name="dealerParent" />
              <Input type="text" label="Location Code" name="locationCode" />
            </FlexBoxInput>
            <FlexBoxInput>
              <Input label="Sale Code" type="text" name="saleCode" />
              <Input label="Outlet Code" type="text" name="outletCode" />
            </FlexBoxInput>
            <FlexBoxInput>
              <Input label="Dealer Map Code" name="dealerMapCode" type="text" />
              <Input label="Bill Series No" type="text" name="billSeriesNo" />
            </FlexBoxInput>
            <FlexBoxInput>
              <Input label="Location Description" name="locationDescription" type="text" />
              <Input label="Dealer Name" type="text" name="dealerName" />
            </FlexBoxInput>
            <FlexBoxInput>
              <Input label="Dealer City" name="dealerCity" type="text" />
              <Input label="Dealer Email ID" type="text" name="dealerEmailID" />
            </FlexBoxInput>
            <FlexBoxInput>
              <Input label="Dealer Admin Name" name="dealerAdminName" type="text" />
              <Input label="Dealer Admin Contact No" type="text" name="dealerAdminContactNo" />
            </FlexBoxInput>
            <CustomButton fullWidth type="submit">
              Sign Up
            </CustomButton>
          </form>
        </FormProvider>
      </SingUpSection>
    </SingUpMain>
  )
}
export default SingUp
