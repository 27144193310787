import React from 'react'
import { Box } from '@mui/material'
import navyGenLogo from '../../assets/powerdby-navygen.svg'
import { LoginForm, LoginMain, LoginSection, LoginTittle, Subtitle, WelcomeTitle } from './login.styles'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../components/button/CommonButton'
import Input from '../../components/input/input'
import { FormProvider, useForm } from 'react-hook-form'
import FlexBox from '../../components/FlexBox'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import LoginInterface from './type'

const LoginSchema = object({
  userName: string().required('User Name is required'),
  userPassword: string().required('Password is required'),
})

const LoginComponent = () => {
  const navigate = useNavigate()

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      userName: '',
      userPassword: '',
    },
  })

  const nextPage = (data: LoginInterface) => {
    navigate('/dashboard')
    console.log(data)
  }

  const handleSingUp = () => {
    navigate('/sing-up')
  }

  return (
    <LoginMain>
      <LoginSection>
        <Subtitle>Claims payments' reconciliation system</Subtitle>
        <LoginTittle>Login</LoginTittle>
        <WelcomeTitle>Welcome! to CPRS</WelcomeTitle>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(nextPage)}>
            <LoginForm>
              <Box padding={'15px 0 13px 0'}>
                <Input type="text" label="User Name" name="userName" />
              </Box>
              <Box padding={'13px 0 13px 0'}>
                <Input type="password" label="Password" name="userPassword" />
              </Box>
              <CustomButton type="submit" fullWidth variant="contained">
                Login
              </CustomButton>
              <FlexBox justify="space-between">
                <Box>
                  <CustomButton
                    onClick={handleSingUp}
                    variant="text"
                    size="small"
                    textColor="#788497"
                    bgColor="transparent"
                    hoverBgColor="transparent"
                    hoverTextColor="#788497"
                  >
                    Sign Up
                  </CustomButton>
                </Box>
                <Box>
                  <CustomButton
                    href="#"
                    variant="text"
                    size="small"
                    textColor="#788497"
                    bgColor="transparent"
                    hoverBgColor="transparent"
                    hoverTextColor="#788497"
                  >
                    Forgot your password?
                  </CustomButton>
                </Box>
              </FlexBox>
            </LoginForm>
          </form>
        </FormProvider>

        <Box component="div" mt={5} marginLeft={'100px'} textAlign={'end'}>
          <img src={navyGenLogo} alt="Navy-GEN" width="200" />
        </Box>
      </LoginSection>
    </LoginMain>
  )
}

export default LoginComponent
