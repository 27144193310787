import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import loginBg from 'assets/bodyshop-login.jpg'
import Typography from 'components/Typography'
import { variables } from 'styles/theme'

export const LoginMain = styled(Box)({
  background: `url(${loginBg}) no-repeat`,
  backgroundSize: 'cover',
  display: 'flex',
  height: '100vh',
  justifyContent: 'flex-end',
})

export const LoginSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 70px',
  [theme.breakpoints.down('md')]: {
    padding: '12px 12px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '8px 8px',
  },
}))

export const Subtitle = styled(Typography.P1Strong)({
  color: '#0e0d35',
  margin: '0 0 ',
})
export const LoginTittle = styled(Typography.H2)({
  color: `${variables.primaryColor}`,
  marginTop: '5px',
  marginBottom: '0px',
})

export const WelcomeTitle = styled(Typography.P3Strong)({
  color: '#788497',
  marginTop: '5px',
  marginBottom: '0px',
})

export const LoginForm = styled(Box)({
  component: 'form',
  marginTop: '16px',
  width: '100%',
  maxWidth: '320px',
  noValidate: true,
})
