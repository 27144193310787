import { get, useController, useFormContext } from 'react-hook-form'
import InputBox from './inputBox.style'
import InputProps from './types'
import FlexBox from '../FlexBox'

const Input = ({ label, name, type, rules = {}, className, ...props }: InputProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const { field } = useController({
    control,
    name,
    rules,
  })

  const error = get(errors, name)
  return (
    <FlexBox direction="column">
      <InputBox label={label} {...field} type={type} error={!!error} {...props} />

      {error && <span style={{ color: 'red' }}>{error.message}</span>}
    </FlexBox>
  )
}

export default Input
